import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { Form, Button, message, Input } from "antd";
import api from "../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { navigate } from "gatsby";
import { AuthContext } from "../../context/GlobalContextProvider";

export default function Contato() {
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [adress, setAdress] = useState();
  const [adress2, setAdress2] = useState();
  const context = useContext(AuthContext);
  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    setLoading(true);
    const { data } = await api.get("/contato");
    setPage(data);
    setAdress(data.endereco);
    setAdress2(data.endereco2);
    setLoading(false);
  };
  const handleSubmit = async (values) => {
    setLoadingSave(true);
    var data = new FormData();
    data.append(
      "data",
      JSON.stringify({
        endereco: values.endereco,
        telefone: values.telefone,
        email: values.email,
        endereco2: values.endereco2,
        telefone2: values.telefone2,
        email2: values.email2,
      })
    );

    await api({
      method: "PUT",
      url: "/contato",
      headers: {
        Authorization: `Bearer ${context?.token}`,
      },
      data,
    }).then((value) => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/contato`);
      }
    });
    setLoadingSave(false);
  };

  return (
    <Layout loading={loading}>
      <Form onFinish={handleSubmit}>
        <SEO
          keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
          title="Contato"
        />
        <h1 className="text-center">Contato</h1>
        <div className="py-24 md:mx-10">
          <div className="mx-2 md:mx-8 mt-5 lg:mt-0">
            <h2 className="mb-6">Sede comercial</h2>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="mx-2 md:mx-4">
                {adress2 && (
                  <iframe
                    className=" w-full border-solid border-2 border-blue-500"
                    title="SoftinLocationMap"
                    height="240"
                    frameBorder="0"
                    src={`https://maps.google.com/maps?q=${encodeURI(
                      adress2
                    )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                    allowFullScreen=""
                  />
                )}
              </div>
              <div>
                <div className="mb-2">
                  <Form.Item
                    initialValue={page?.endereco2}
                    rules={[{ required: true, message: "Digite um endereço" }]}
                    name="endereco2"
                  >
                    <Input
                      placeholder="Digite o endereço"
                      className="mb-6"
                      value={adress2}
                      onChange={(e) => setAdress2(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="mb-2">
                  <Form.Item initialValue={page?.telefone2} name="telefone2">
                    <Input placeholder="Digite o Telefone" className="mb-6" />
                  </Form.Item>
                </div>
                <div className="mb-2">
                  {" "}
                  <Form.Item initialValue={page?.email2} name="email2">
                    <Input placeholder="Digite o e-mail" className="mb-6" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-2 md:mx-8 mt-5 lg:mt-0">
            <h2 className="mb-6">Base administrativa</h2>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="mx-2 md:mx-4">
                {adress && (
                  <iframe
                    className=" w-full border-solid border-2 border-blue-500"
                    title="SoftinLocationMap"
                    height="240"
                    frameBorder="0"
                    src={`https://maps.google.com/maps?q=${encodeURI(
                      adress
                    )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                    allowFullScreen=""
                  />
                )}
              </div>
              <div>
                <div className="mb-2">
                  <Form.Item
                    initialValue={page?.endereco}
                    rules={[{ required: true, message: "Digite um endereço" }]}
                    name="endereco"
                  >
                    <Input
                      placeholder="Digite o endereço"
                      className="mb-6"
                      value={adress}
                      onChange={(e) => setAdress(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="mb-2">
                  <Form.Item initialValue={page?.telefone} name="telefone">
                    <Input placeholder="Digite o Telefone" className="mb-6" />
                  </Form.Item>
                </div>
                <div className="mb-2">
                  <Form.Item initialValue={page?.email} name="email">
                    <Input placeholder="Digite o e-mail" className="mb-6" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form.Item>
          <Button
            className="mt-10"
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
